<template>
    <div class="home">
        <div class="top flex">
            <img @click="goHome('')" class="logo" src="@/assets/home2/logo@2x.png" alt="">
            <div class="flex">
                <div class="nav" @click="goHome('#about')">关于赛事</div>
                <div class="nav" @click="goHome('#format')">赛事赛制</div>
                <div class="nav"  @click="goHome('#review')">评审介绍</div>
                <div class="nav" style="color:#45B79C; font-weight: bold">赛事报名</div>
            </div>
        </div>
        <div class="main">
            <div v-if="step == 1" class="content">
                <div class="title">忘记密码</div>
                <div class="line"></div>
                <div class="flex">
                    <div class="bg1">步骤一: 手机号验证</div>
                    <div class="bg2">步骤二: 重置密码</div>
                </div>
                <div class="flex group">
                    <div class="part1">手机号</div>
                    <input v-model="phone" type="text" placeholder="请输入您的手机号码">
                </div>
                <div class="flex group">
                    <div class="part1">验证码</div>
                    <input type="text" v-model="yzm" placeholder="请输入您的验证码">
                    <!-- <div v-if="!isCountdown" class="send-btn" @click="getCode">发送验证码</div>
                    <div v-else class="send-btn">{{time}}</div> -->
                    <div class="send-btn" @click="judge">{{time}}</div>
                </div>
                <div v-if="phone && yzm" class="next-btn" @click="VerCode">下一步</div>
                <div v-else class="no-next-btn next-btn">下一步</div>
            </div>
            <div v-if="step == 2" class="content">
               <div class="title">忘记密码</div>
                <div class="line"></div>
                <div class="flex">
                    <div class="bg3">步骤一: 手机号验证</div>
                    <div class="bg4">步骤二: 重置密码</div>
                </div>
                <div class="flex group">
                    <div class="part2">新密码</div>
                    <input v-model="newPassword" type="password" placeholder="请输入您的新密码">
                </div>
                <div class="flex group">
                    <div class="part2">确认新密码</div>
                    <input v-model="confirmpass" type="password" placeholder="请再次输入您的新密码">
                </div>
                <div v-if="newPassword && confirmpass" @click="checkpswd" class="next-btn" style="margin-left:200px; margin-top: 70px">确认</div>
                <div v-else class="no-next-btn next-btn" style="margin-left:200px; margin-top: 70px">确认</div>

            </div>
        </div>

        <!-- footer -->
        <div id="sign" class="footer">
        <img class="logobottom" src="@/assets/home1/logobottom@2x.png" alt="">
        <div class="text-wrap flex-vcbetween">
            <div>
            <div><span>指导单位</span>中共常熟市委宣传部</div>
            <div><span>主办单位</span>常熟文旅发展有限责任公司</div>
            <div><span>承办单位</span>吉捷文化传媒（上海）有限公司</div>
            </div>
            <div class="flex-vc">
            <div><span>官方热线:</span></div>
            <div>
                <div>邮箱: 962991363@qq.com</div>
                <div>微信: 13817518262</div>
            </div>
            </div>
        </div>
        <div class="text-bottom flex-between">
            <div class="fs13"><img class="c" src="@/assets/home1/C@2x.png" alt="">2021 <span style="font-weight: bold">changshustory.</span> ALL Right Reserved <a style="color:#666" href="https://beian.miit.gov.cn/">沪ICP备12038716号-10</a></div>
            <div class="fs15"><span>关于赛事</span>|<span>赛事赛制</span>|<span style="margin-right:0">评审介绍</span></div>
        </div>
        </div>

    </div>
</template>
<script>
import { service } from '@/util/api.js'

export default {
    data() {
        return {
            step: 1,
            isCountdown: false, //倒计时状态
            time: '发送验证码', //倒计时 
            currentTime: 61, // 当前倒计时
            phone: '',
            yzm: '',//验证码
            newPassword: '',//新密码
            confirmpass: ''//确认新密码
        }
    },
    methods: {
        goHome(id){
            this.$router.push({name:'Home',query: {id: id}})
        },
        getCode() {
          this.isCountdown = true;
            let that = this;
                let clock = setInterval(function(){
                that.currentTime--;
                that.time = that.currentTime + 's'
                if (that.currentTime <= 0) {
                    clearInterval(clock)
                    that.time = '',
                    that.currentTime = 61,
                    that.isCountdown = false
                }
            }, 1000); //一秒执行一次
        },
        //发送验证码按钮
        judge() {
          if(this.time == '发送验证码') {
            this.getCode()
            this.GetCode()
          }
        },
        //发送验证码
        async GetCode() {
          let parmas = {
            methodName: 'GetCode',
            phone: this.phone
          }
          let res = await service(parmas)
        },
        // 验证验证码
        async VerCode() {
          let parmas = {
            methodName: 'VerCode',
            code: this.yzm,
            phone: this.phone,
          }
          let res = await service(parmas)
          if(res.Value.State) {
            this.step = 2;
          }else {
            this.$message.error('验证码错误');
          }
        },
        // 验证新密码
        checkpswd() {
          if(this.newPassword != this.confirmpass) {
            this.$message.error('密码输入不一致！');
          } else if(this.newPassword.length < 6) {
            this.$message.error('密码不能低于6位数！');
          } else {
            this.Change();
          }
        },
        async Change() {
          let parmas = {
            methodName: 'Change',
            newPassword: this.newPassword,
            phone: this.phone,
          }
          let res = await service(parmas)
          if(res.Value.State) {
            this.$message.success(res.Value.Tips);
            setTimeout(()=> {
              this.$router.push('/sign')
            }, 1000 )
            
          }else {
            this.$message.error(res.Value.Tips);
          }
        }
    }

}
</script>
<style>
.el-message {
    font-size: 15px;
}
</style>
<style lang="less" scoped>
.flex {
  display: flex;
  align-items: center;
}
.flex-vc {
  display: flex;
  justify-content: center;
}
.flex-vcbetween {
  display: flex;
  // align-items: center;
  justify-content: space-between;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home {
    background: #F7F7F7;
  margin: 0 auto;
  width: 1920px;
  .top {
      background: #FFFFFF;
    height: 134px;
    .logo {
      width: 200px;
      margin-left: 360px;
      margin-right: 328.5px;
      cursor: pointer;
    }
    .nav:hover {
      color:#45B79C;
      font-weight: bold;
      cursor: pointer
    }
    .nav {
      width: 175px;
      font-size: 20px;
      position: relative;
      a {
        text-decoration: none;
        color: #333333;
      }
      span {
        position: absolute;
        left: 49.5px;
        top: 15px;
        display: block;
        width: 76px;
        height: 10px;
        background: #AAAAAA;
        z-index: -1;
      }
    }
  }
    .main {
        height: 770px;
        width: 1200px;
        background: url('~@/assets/registered/bg@2x.png');
        background-size: 100% 100%;
        margin: 19px auto;
        padding: 19px 0;
        .content {
            width: 604px;
            margin: 0 auto;
            .title {
                font-size: 24px;
                color: #45B79C;
                font-weight: bold;
                margin: 26px 0;
            }
            .line {
                width: 604px;
                height: 1px;
                background: #E5E5E5;
                margin-bottom: 56px;
            }
            .bg1 {
                width: 308px;
                line-height: 46px;
                background: url('~@/assets/password/bg1@2x.png');
                background-size: 100% 100%;
                font-size: 18px;
                color: #FFFFFF;
                margin-bottom: 66px;
            }
            .bg2 {
                width: 308px;
                line-height: 46px;
                background: url('~@/assets/password/bg2@2x.png');
                background-size: 100% 100%;
                margin-left: -10px;
                font-size: 18px;
                color: #BFBFBF;
                margin-bottom: 66px;
            }
            .bg3 {
                width: 308px;
                line-height: 46px;
                background: url('~@/assets/password/bg3@2x.png');
                background-size: 100% 100%;
                font-size: 18px;
                color: #BFBFBF;
                margin-bottom: 90px;

            }
            .bg4 {
                width: 308px;
                line-height: 46px;
                background: url('~@/assets/password/bg4@2x.png');
                background-size: 100% 100%;
                margin-left: -10px;
                font-size: 18px;
                color: #FFFFFF;
                margin-bottom: 90px;
            }
            .group {
                margin: 24px 0;
                .part1 {
                    font-size: 18px;
                    color: #231815;
                    width: 54px;
                    text-align: right;
                    margin: 0 31px 0 15px;
                }
                .part2 {
                    width: 170px;
                    font-size: 18px;
                    color: #231815;
                    text-align: right;
                    text-align: right;
                    margin-right: 31px;

                }
                input {
                    width: 274px;
                    height: 46px;
                    border: 1px solid #DCDCDC;
                    font-size: 18px;
                    padding: 0 24px;
                }
                .send-btn {
                    width: 136px;
                    line-height: 46px;
                    background: #45B79C;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin-left: 31px;
                }
            }
 
            .next-btn {
                width: 322px;
                line-height: 46px;
                background: #45B79C;
                font-size: 18px;
                color: #FFFFFF;
                margin: 70px auto;
            }
            .no-next-btn {
                opacity: 0.5;
            }

        }
    }
    .footer {
    width: 1920px;
    height: 170px;
    background: #C1C1C1;
    position: relative;
    .logobottom {
      position: absolute;
      top: 38px;
      left: 860px;
      width: 200px;
    }
    .text-wrap {
      width: 1170px;
      margin: 0 auto ;
      color: #666666;
      font-size: 13px;
      line-height: 32px;
      text-align: left;
      padding-top: 30px;
      span {
        font-weight: bold;
        font-size: 15px;
        // color: #FFFFFF;
        margin-right: 16px;
        display: inline-block;
      }
    }
    .text-bottom {
      width: 1170px;
      margin: 0 auto;
      line-height: 32px;
      .fs13 {
        font-size: 13px;
        color: #666;
        img {
          width: 10px;
        }
      }
      .fs15 {
        font-size: 15px;
        color: #ABACB1;
        span {
          color: #666666;
          margin: 0 26px;
          display: inline-block;
        }
      }
    }

  }
}
</style>

